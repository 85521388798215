import $ from 'jquery';
import React from 'react';
import LogoImg from '../assets/images/logo_p_small.png';
import {UserToken,getUserObjData,getAccessFunctions} from '../function_lib/userToken';
import {setProfilePic,defaultClick,getAccessRolesNew,CallMapMethod,CallMapMethod_CBack} from '../function_lib/common_lib';
import {CallCMSMapMethod} from '../function_lib/common_cms';
import UserDefault from '../assets/images/9.png';


import './header.css';
let listRoles;
let loginUser;
let userObject;
let companyName="";
let clsObject;

class Header extends React.Component{
  logout(){
    localStorage.clear();
    window.location.href="../";
  }

  

  constructor(props){
  super(props);
    listRoles=[];
    if(localStorage.getItem("userData")!=null){
      userObject= getUserObjData();
      //alert(JSON.stringify(userObject));
      loginUser=userObject[0].first_name;
      if(typeof userObject[0].company_name!=="undefined"){
        companyName=userObject[0].company_name;
      }
      
      
  }
  let ret=getAccessFunctions();
 if(userObject[0].type==="1" || userObject[0].type==="2" || userObject[0].type==="3"){

    listRoles.push({"id":"1","name":"Dashboard","parent_id":"0","red_link":"../dashboard","description":"","display_option":"menu"});
    if(userObject[0].type==="2" && ret==="user,master"){
      listRoles.push({"id":"5","name":"Masters","parent_id":"0","red_link":"../dashboard","description":"","display_option":"menu"});
    listRoles.push({"id":"51","name":"Master Dashboard","parent_id":"5","red_link":"../dashboard","description":"","display_option":"menu"});
    listRoles.push({"id":"52","name":"Channel Mgmt","parent_id":"5","red_link":"../channel_mgmt","description":"","display_option":"menu"});
    listRoles.push({"id":"53","name":"Thrashold Limit/Slot Size","parent_id":"5","red_link":"../thrashold_limit","description":"","display_option":"menu"});
    listRoles.push({"id":"52","name":"Client Nos","parent_id":"5","red_link":"../client_nos","description":"","display_option":"menu"});
    listRoles.push({"id":"54","name":"User Settings","parent_id":"5","red_link":"../user_settings","description":"","display_option":"menu"});
    listRoles.push({"id":"55","name":"Queue Monitor","parent_id":"5","red_link":"../queue_monitor","description":"","display_option":"menu"});
    listRoles.push({"id":"56","name":"Push Messages","parent_id":"5","red_link":"../push_messages","description":"","display_option":"menu"});


    
    listRoles.push({"id":"7","name":"User Mgmt","parent_id":"0","red_link":"../user_mgmt","description":"","display_option":"menu"});
    }
    else if(userObject[0].type==="1" && ret==="user"){
      // access menu for use
      listRoles.push({"id":"7","name":"User Mgmt","parent_id":"0","red_link":"../user_mgmt","description":"","display_option":"menu"});
    }
    
    listRoles.push({"id":"2","name":"Send SMS","parent_id":"0","red_link":"../wapp_campaign","description":"","display_option":"menu"});
    listRoles.push({"id":"21","name":"Wapp Campaign","parent_id":"2","red_link":"../wapp_campaign","description":"","display_option":"menu"});
    //listRoles.push({"id":"21","name":"Wapp Button Campaign","parent_id":"2","red_link":"../wapp_button_campaign","description":"","display_option":"menu"});

    listRoles.push({"id":"3","name":"Credit History","parent_id":"0","red_link":"../credit_history","description":"","display_option":"menu"});

    listRoles.push({"id":"4","name":"Reports","parent_id":"0","red_link":"../compliance_list","description":"","display_option":"menu"});
    listRoles.push({"id":"42","name":"View Reports","parent_id":"4","red_link":"../wapp_reports","description":"","display_option":"menu"});
    //listRoles.push({"id":"41","name":"Wapp Reports","parent_id":"4","red_link":"../wapp_reports","description":"","display_option":"menu"});
    //listRoles.push({"id":"42","name":"Wapp Button Reports","parent_id":"4","red_link":"../wapp_button_reports","description":"","display_option":"menu"});
    

    listRoles.push({"id":"6","name":"Profile","parent_id":"0","red_link":"../user_profile","description":"","display_option":"menu"});
    //listRoles.push({"id":"7","name":"Manage APIs","parent_id":"0","red_link":"../abstract_noticesssss","description":"","display_option":"menu"});
  }
  
  
   
    if(localStorage.getItem("userDS")!=null){
      userObject= JSON.parse(localStorage.getItem("userDS"));
      //alert(JSON.stringify(userObject));
      loginUser=userObject[0].first_name;
      if(typeof userObject[0].company_name!=="undefined"){
        companyName=userObject[0].company_name;
      }
  }
  }
  callBackNg(data,msg){
   var check=JSON.parse(data);
   if(check.length==0){
    // alert("Invalid User !Kindly contact to Admin")
    localStorage.clear();
    window.location.href="../";
   }
  }
  
  componentDidMount(){

    
    if(listRoles.length!=0){
      //alert("i am in ");
      var listHtm=getAccessRolesNew(listRoles);
     
     $("#menu_opt").append(listHtm);
    }
    
  }
 
    render(){
        return(//  <!-- Navbar -->
          <div>
          <nav className="main-header navbar navbar-expand-md  navbar-light navbar-white my-shadow">
          <div className="container-fluid "> <a href="/dashboard" className="navbar-brand"> <img src={LogoImg} alt="HrTech" className="brand-image elevation-3 img-thumbnail" /> <span className="brand-text " ></span> </a>
            <button className="navbar-toggler order-1 me-hide" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span> </button>
            <div className="collapse navbar-collapse order-3" id="navbarCollapse"> 
           
              {/* <!-- Left navbar links -->  */}
              
              {/* <!-- SEARCH FORM -->  */}
              
            </div>
          
            
            {/* <!-- Right navbar links --> */}
            <ul className="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">
            {/* <select className="form-control">
                 <option>Year</option>
                 <option>2021</option>
            </select> */}
              {/* <!-- Messages Dropdown Menu --> */}
              <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i style={{fontSize:"2em"}} class="fa fa-bell"></i>
          <span class="badge badge-warning navbar-badge" id="count_notification"></span>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <span class="dropdown-item dropdown-header">15 Notifications</span>
          <div class="dropdown-divider"></div>
          <a href="../recent_notification" class="dropdown-item">
            <i class="fa fa-envelope mr-2"></i>Recent Compliance
            <span class="badge badge-warning float-right text-muted text-sm" id="compliance_count"></span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fa fa-users mr-2"></i>Recent Notices
            <span class="float-right text-muted text-sm" id="notices_count"></span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fa fa-file mr-2"></i>ACTA Updates
            <span class="float-right text-muted text-sm" id="acta_count"></span>
          </a>
          <div class="dropdown-divider"></div>
          {/* <a href="#" class="dropdown-item dropdown-footer">See All Notifications</a> */}
        </div>
      </li>
        
            
              <li className="nav-item dropdown12"> <a className="nav-link" data-toggle="dropdown" href="#"> <i style={{fontSize:"2em"}} className="fa fa-user fa-2x"></i> </a>
                <div id="dropdown12" className="dropdown-menu dropdown-menu-lg dropdown-menu-right"> 
                  <div className="dropdown-divider"></div>
                  <a href="#" className="dropdown-item"> <i className="fa fa-book mr-2"></i> <b>Entity:</b> {companyName}</a>
                  <a href="#" className="dropdown-item"> <i className="fa fa-user mr-2"></i> Welcome {loginUser}</a>
                  <div className="dropdown-divider"></div>
                  
                  <a href="javascript:void(0)" onClick={()=>this.logout()} className="dropdown-item"> <i className="fa fa-sign-out mr-2"></i> Logout </a>
                  <div className="dropdown-divider"></div>
                  </div>
              </li>
            </ul>
          </div>
        </nav>
          <nav class="navbar navbar-expand-lg my-bg-color-1 my-shadow">
          <a class="navbar-brand text-white ml-3" href="#"><i class="fa fa-user-circle"></i> {loginUser}  </a>
          <ul class="navbar-nav">
              
          </ul>
        
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#nav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"><i class="fa ffg fa-bars "></i></span>
          </button>
        
          <div class="collapse navbar-collapse" id="nav">
        
        
              <ul id="menu_opt" class="navbar-nav bg-light-my m-0 ml-lg-auto p-3 p-lg-0 m-auto1">
              <li class="d-inline d-lg-none">
                <button data-toggle="collapse" data-target="#nav" class="close float-right">&times;</button>
            </li>
            <li class="nav-item d-lg-none d-md-none d-sm-none  "><img src={UserDefault} width="80" class="img-circle img-thumbnail img-fluid m-auto text-cente" />
				<h6 class="mt-4">Good-Morning {loginUser} </h6>
				<div class="border-bottom w-100 mt-1 mb-1"></div>
			
			</li>
              </ul>
          </div>
      </nav>


      </div>
          )
    }
}
export default Header