import React from 'react';
class Footer extends React.Component{
    render(){
        return(<div><footer className="main-footer fixed-bottom my-bg-color-1"> 
        <strong>Copyright <a target='_blank' href="https://softsms.in/">SoftSMS</a>.</strong> All rights reserved. </footer>
        <div className="container-fluid bg-white fixed-bottom my-shadow d-lg-none d-md-none d-block d-sm-none">
        {/* <div className="row">
        <div className="col-3 pt-2 border-right">
        <p className="text-center p-margin"><span className="material-icons text-center m-auto text-orange">
      account_balance
      </span>	</p>
         
          
          </div>
          <div className="col-3 pt-2 border-right">
        <p className="text-center p-margin"><span className="material-icons text-center m-auto text-orange">
      contact_support
      </span>	</p>
         
          
          </div>
          
           <div className="col-3 pt-2 border-right">
        <p className="text-center p-margin"><span className="material-icons text-center m-auto text-orange">
      production_quantity_limits
      </span>	</p>
          
          
          </div>
          
          <div className="col-3 pt-2 border-right">
        <p className="text-center p-margin"><span className="material-icons text-center m-auto text-orange">
      contactless
      </span>	</p>
          
          </div> 
    </div>*/}
          </div>
          </div>
        );
    }
}
export default Footer;