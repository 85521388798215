import $, { extend } from 'jquery';
import React from 'react';
import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,CallMapMethod_CBack} from '../function_lib/common_lib';
import Header from '../user_pages/header';
import Footer from '../user_pages/footer';
import Option_Module from '../user_pages/option_module';
import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';

import Wa_User_Limits from '../components/wa_user_limits';


let loginUser="";
let userObject="";
let companyName="";
let clsUserMgmt;

let rf="0";
class User_Settings extends React.Component{
  forceUpdateHandler(){
    this.forceUpdate();
  };

    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }
      this.state={ listUsers:[],crediUser:{},creditBalance:[],
                  stActUser:{},stActType:""};
          this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
      clsUserMgmt=this;
      }

      getUsers(status="0"){
        //child component use this method loan_document_upload_comp
        var map=[];
        map.push({name:"curl_type",value:"getUserSettings"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        //alert(status);
        /*
        if(status!=="0"){
          map.push({name:"status",value:status});
        }
        not required as of now
        */
        map.push({name:"upper_id",value:userObject[0].upper_id});
        map["res_func"]="callBackrrr";
        map["curl_type"]="getUserSettings";
        CallMapMethod_CBack(clsUserMgmt,map,function(data,msg){
          //alert(data);
            if(data!=="0" && data!==""){
                let listData=JSON.parse(data);
                var strData="";
                clsUserMgmt.setState({listUsers:listData},()=>{
                  clsUserMgmt.forceUpdateHandler();
                  //RespBkMthdMapp(clsUserMgmt,"_btnShareOption","");
                 
                  if(rf!=="0"){
                    clsUserMgmt.getSearch(rf);
                    //alert("i m in setup "+rf);
                    $("#serLoanStatus").val(rf);
                  }
                });
                
               
              }
        });
      }
      okSucc(data,smg){
        if(data==="001"){
          alert("record added successfully");
          clsUserMgmt.getUsers();
          $("#frmCreate").find("input[type=text]").val("");
        }else{
          alert(smg);
        }
        // alert(data);
      }

okSuccLimitSet(data,msg)
{
  if(data==="001"){
    alert(msg);
    window.location.reload();
  }else{
    alert("Unable to set limit , kindly check again..");
  }
    
}

getCreditBalance(acc_inf_id){
  var map=[];
  map.push({name:"curl_type",value:"getCreditBalance"});
  map.push({name:"res_func",value:"callBackrrr"});
  map.push({name:"local_token",value:userObject[0].app_user_id});
  map.push({name:"acc_inf_id",value:acc_inf_id});
  
  map["res_func"]="callBackrrr";
  map["curl_type"]="getCreditBalance";
  CallMapMethod_CBack(clsUserMgmt,map,function(data,msg){
    //alert(data);
      if(data!=="0" && data!==""){
          let listData=JSON.parse(data);
          var strData="";
          clsUserMgmt.setState({creditBalance:listData},()=>{
            clsUserMgmt.forceUpdateHandler();
          })
          
          
         
        }
  });
}
 onSettingLimits(userAct,actType){
  // method will work on click on Credit Button Link to set State
  clsUserMgmt.setState({stActUser:userAct,stActType:actType},()=>{
    //clsUserMgmt.getCreditBalance(userCredit["id"]);
  })

 }             
      
componentDidMount(){
    
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].app_user_id);
this.getUsers();
}


      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
<div className="row content12 mt-2 mb-2">

<div className="col-lg-2 col-md-2 col-sm-4 float-right">
<select  className="form-control form-control-sm" id="ser_month" name="ser_month">
           <option value="0">-User Type-</option>
           <option value="reseller">-Reseller-</option>
           <option value="user">-User-</option>
          
         </select>
</div>

<div className='col-lg-4 col-md-4 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               <input autoComplete='off' type="text" id="col78" name="col78" className="_setSearchKeyUp form-control form-control-sm" placeholder='search' />
             </div>
         
         </div>

</div>

<div className="col-lg-2 col-md-2 col-sm-4 vcenter-item1 float-right">
<div className="input-group">
               
               <div className="input-group-prepend">
               <div className='col-12 '>
                 <div onClick={()=>this.getSerComplianceList()}  style={{marginRight:"10px"}} className="btn btn-sm btn-danger">Search</div>
                 <div  className="btn btn-sm btn-danger">Reset</div>
                 </div>
               </div>
             </div>

</div>
    </div>    
<div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
         
      <div class="card">
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>User Settings</b></h3>
                
                {/* <a className="btn btn-sm btn-danger float-right" data-toggle="modal" data-target="#divCreateUser" href="javascript:void(0)">Add New</a> */}
              </div>
                <table class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap11 table-responsive">
                  <thead>
                    <tr class="border">
                      <th>S.No.</th>
                      <th>User Name</th>
                      <th>Entity Name</th>
                      <th>User Type</th> 
                      <th>Enrollment Date</th>
                      <th>Day Thrashold Limit/Current</th>
                      <th>Day Limit/Current</th>
                      <th>API Limit/Current</th>
                      <th>Created By</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody id="tbData">
                  {(this.state.listUsers.map((item,index)=>{
                       return(<tr>
                        <td>{index+1}</td>
                    <td>{item.user_name}</td>
                  <td>{item.first_name}</td>
                  <td>{item.type_name}</td>
                  <td>{item.date}</td>
                  <td>{item.day_limit_thrash}</td>
                  <td>{item.day_limit_panel}</td>
                  <td>{item.api_limit}</td>
                  <td><a data-toggle="modal" data-target="#divEmployee" href="javascript:void(0)">{item.upper_user}</a></td>
                  <td><span className="badge bg-danger dash_rejected_claim1">{item.status}</span></td>
                  <td>
                  <div class="btn-group">
                    <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" area-expanded="false">
                      Action
                    </button>
                    <div class="dropdown-menu">
                      <a class="dropdown-item" onClick={()=>this.onSettingLimits(item,"thrashold limit")}  data-toggle="modal" data-target="#divWaUserLimit" href="javascript:void(0)">Day Thrashold Limit</a>
                      <a class="dropdown-item" data-toggle="modal" onClick={()=>this.onSettingLimits(item,"day limit")} data-target="#divWaUserLimit" href="javascript:void(0)">Day Limit</a>
                      <a class="dropdown-item" onClick={()=>this.onSettingLimits(item,"api limit")} data-toggle="modal" data-target="#divWaUserLimit" href="javascript:void(0)">API Limit</a>
                    </div>
                  </div>
                  </td>
                    </tr>)
                  }))}
                  </tbody>
                </table>
             
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Footer/>
<Wa_User_Limits act_type={this.state.stActType} act_user={this.state.stActUser} app_user_id={userObject[0].app_user_id}/>
    
          </div>)
      }
}
export default User_Settings;