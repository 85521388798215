import $, { extend } from 'jquery';
import React from 'react';
import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,GetImgCertUser_CBack,RespBkMthdMapp,detectEmojisAndSpecialCharacters} from '../function_lib/common_lib';

import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import {Custom_Alert,Alert_Content} from '../components/custom_alert';
import Header from '../user_pages/header';
import Footer from '../user_pages/footer';


import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';



let loginUser="";
let userObject="";
let companyName="";
let clsWappButCamp;

let totalFileAllow=1;

let objImages = [];
let indexExisting=0;
let arrExistingFiles=[];

let objVideo=[];
let vidIndexExisting=0;
let arrVidExistingFiles=[];

let objPdf=[];
let pdfIndexExisting=0;
let arrPdfExistingFiles=[];

class Wapp_Button_Campaign extends React.Component{
    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }
      clsWappButCamp=this;
      }
   //  check file validation
   fileValidation(){
    let allowFile=true;
 let imgCount=objImages.length;
 let vidCount=objVideo.length;
 let pdfCount=objPdf.length;

 let vTotalCount=imgCount+vidCount+pdfCount;
 if(vTotalCount>=totalFileAllow){
  alert("Only "+totalFileAllow+" to be allowed .");
  allowFile=false;
 }
 return allowFile;
}
//  check file validation       
      rmPdfProc(data){
        if(document.getElementById("ref_pdf_"+data)!=null){
          document.getElementById("ref_pdf_"+data).remove();
        }
        
        this.lcRemoveJSON(data,"pdf");
      }    

PdfThumbBack(flImg,flName,fObject,othInfo=""){
  if(!this.fileValidation()){
    return;
  }
   if(othInfo===""){
    othInfo="nm"+pdfIndexExisting+","+pdfIndexExisting;
  }
  pdfIndexExisting++;
  var arrNmValue=othInfo.split(',');
  //arrExistingFiles.push
  arrPdfExistingFiles[arrNmValue[1]]=arrNmValue[0];
  var strSet = "<div id=\"ref_pdf_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-6 lsthumb image_thumb_parent\">" +
  "<i class=\"fa fa-close icon-close _removeBtn"+pdfIndexExisting+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
    "<a class=\"gallery-item\" href=\""+flImg+"\"><img src=\"" + flImg + "\"/></a>" +
    "</div>";
    if(flImg.indexOf("data:video/webm")!=-1 || flImg.indexOf("data:video/mp4")!=-1){
     strSet= "<div id=\"ref_pdf_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-12 lsthumb image_thumb_parent\">" +
      "<i class=\"fa fa-close icon-close _removeBtn"+pdfIndexExisting+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
        "<a class=\"gallery-item\" href=\""+flImg+"\"><video controls type=\"video/mp4\" src=\"" + flImg + "\"/></a>" +
        "</div>";
    }
    
  //$("#audios").val("{\"objs\":" + JSON.stringify(objAudio) + "}");
  objPdf.push({ name: arrNmValue[1], value: flImg });
  $("#lsPdf").val("{\"objs\":" + JSON.stringify(objPdf) + "}");
  //alert(strSet);            
  $(".pdfLibThumb").append(strSet);
  if(fObject!==null){
    fObject.val('');
  }

RespBkMthdMapp(clsWappButCamp, "_removeBtn"+indexExisting, "rmPdfProc", "2");
  //imgLibThumb
}      
rmVideoProc(data){
  if(document.getElementById("ref_vid_"+data)!=null){
    document.getElementById("ref_vid_"+data).remove();
  }
  
  this.lcRemoveJSON(data,"video");
}
VideoThumbBack(flImg,flName,fObject,othInfo=""){
  if(!this.fileValidation()){
    return;
  }
  if(othInfo===""){
    othInfo="nm"+vidIndexExisting+","+vidIndexExisting;
  }
  vidIndexExisting++;
  var arrNmValue=othInfo.split(',');
  //arrExistingFiles.push
  arrVidExistingFiles[arrNmValue[1]]=arrNmValue[0];
  var strSet = "<div id=\"ref_vid_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-6 lsthumb image_thumb_parent\">" +
  "<i class=\"fa fa-close icon-close _removeBtn"+arrVidExistingFiles+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
    "<a class=\"gallery-item\" href=\""+flImg+"\"><img src=\"" + flImg + "\"/></a>" +
    "</div>";
    if(flImg.indexOf("data:video/webm")!=-1 || flImg.indexOf("data:video/mp4")!=-1){
     strSet= "<div id=\"ref_vid_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-12 lsthumb image_thumb_parent\">" +
      "<i class=\"fa fa-close icon-close _removeBtn"+arrVidExistingFiles+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
        "<a class=\"gallery-item\" href=\""+flImg+"\"><video controls type=\"video/mp4\" src=\"" + flImg + "\"/></a>" +
        "</div>";
    }
    
  //$("#audios").val("{\"objs\":" + JSON.stringify(objAudio) + "}");
  objVideo.push({ name: arrNmValue[1], value: flImg });
  $("#lsVideo").val("{\"objs\":" + JSON.stringify(objVideo) + "}");
  //alert(strSet);            
  $(".vidLibThumb").append(strSet);
  if(fObject!==null){
    fObject.val('');
  }

RespBkMthdMapp(clsWappButCamp, "_removeBtn"+indexExisting, "rmVideoProc", "2");
  //imgLibThumb
}      
rmFileProc(data){
  
    if(document.getElementById("ref_div_"+data)!=null){
      document.getElementById("ref_div_"+data).remove();
    }
    
    this.lcRemoveJSON(data,"image");
}
/* Loc */
lcRemoveJSON(refKey,fType){
if(fType==="image"){
  var jsonP=JSON.parse($("#lsImages").val());
  var arrJson=jsonP.objs;
  for(var ai=0;ai<arrJson.length;ai++){
    if(arrJson[ai]["name"]===refKey){
      arrJson.splice(ai,1);
      $("#lsImages").val(JSON.stringify(jsonP));
      
      break;
    }
  }
  objImages=arrJson;
  
}else if(fType==="video"){
  var jsonP=JSON.parse($("#lsVideo").val());
  var arrJson=jsonP.objs;
  for(var ai=0;ai<arrJson.length;ai++){
    if(arrJson[ai]["name"]===refKey){
      arrJson.splice(ai,1);
      $("#lsVideo").val(JSON.stringify(jsonP));
      
      break;
    }
  }
  objVideo=arrJson;
}else if(fType==="pdf"){
  var jsonP=JSON.parse($("#lsPdf").val());
  var arrJson=jsonP.objs;
  for(var ai=0;ai<arrJson.length;ai++){
    if(arrJson[ai]["name"]===refKey){
      arrJson.splice(ai,1);
      $("#lsPdf").val(JSON.stringify(jsonP));
      
      break;
    }
  }
  objPdf=arrJson;
}


 
}     
ImageThumbBack(flImg, flName,fObject,othInfo="") {
  if(!this.fileValidation()){
    return;
  }
  if(othInfo===""){
    othInfo="nm"+indexExisting+","+indexExisting;
  }
  indexExisting++;
  var arrNmValue=othInfo.split(',');
  //arrExistingFiles.push
  arrExistingFiles[arrNmValue[1]]=arrNmValue[0];
  var strSet = "<div id=\"ref_div_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-6 lsthumb image_thumb_parent\">" +
  "<i class=\"fa fa-close icon-close _removeBtn"+indexExisting+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
    "<a class=\"gallery-item\" href=\""+flImg+"\"><img src=\"" + flImg + "\"/></a>" +
    "</div>";
    if(flImg.indexOf("data:video/webm")!==-1 || flImg.indexOf("data:video/mp4")!==-1){
     strSet= "<div id=\"ref_div_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-12 lsthumb image_thumb_parent\">" +
      "<i class=\"fa fa-close icon-close _removeBtn"+indexExisting+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
        "<a class=\"gallery-item\" href=\""+flImg+"\"><video controls type=\"video/mp4\" src=\"" + flImg + "\"/></a>" +
        "</div>";
    }
    
  //$("#audios").val("{\"objs\":" + JSON.stringify(objAudio) + "}");
  objImages.push({ name: arrNmValue[1], value: flImg });
 // alert(objImages.length);
  $("#lsImages").val("{\"objs\":" + JSON.stringify(objImages) + "}");
  //alert(strSet);            
  $(".imgLibThumb").append(strSet);
  if(fObject!==null){
    fObject.val('');
  }
//   $('.gallery-item').magnificPopup({
//     type: 'image',
//     gallery: {
//         enabled: true
//     }
// });
RespBkMthdMapp(clsWappButCamp, "_removeBtn"+indexExisting, "rmFileProc", "2");
  //imgLibThumb
      }

     saveWappCompaign(){

  
      let checkChar= $("#msg_text").val();
      let result=detectEmojisAndSpecialCharacters(checkChar);
     
   if(result.emojis.length!==0 || result.specialCharacters.length!==0){
      alert("Special Characters and Emojis not allowd in text Message");
      return; 
   }
       

      let user_captcha = document.getElementById('user_captcha').value;
  if (validateCaptcha(user_captcha)===true) {
  
  GetImgCertUser_CBack(clsWappButCamp,"#frmWappCamp","createWappCampaign",function(checkData,checkMsg,userMsg){
    if(checkData===-10){
      alert("Kindly fill required fields");
      return;
    }else {
      Custom_Alert("Wapp Campaign",checkMsg);
      //alert(checkMsg);
    }
    
  })
}else{
  alert("Invalid Code!");
}
  
     }

 
componentDidMount(){
   
  loadCaptchaEnginge(6); 
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].id);
}

   render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
<div className="row">
<div className="col-md-12 col-lg-12"> 
        
<section class="content">
<div class="container-fluid">
<div class="card card-secondary">
<div class="card-header">
                                <h3 class="card-title">Wapp Button Message</h3>
                            </div>
                            <form class="form-group" id="frmWappCamp" name="frmWappCamp" enctype="multipart/form-data" method="POST" onsubmit="event.preventDefault()">
                            <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                            <input type="hidden" name="curl_type" id="curl_type" value="createWappCampaign" />
                            <input type="hidden" name="m_type" id="m_type" value="createWappCampaign" />
                            <input type="hidden" name="wa_msg_ty_ma_id" id="wa_msg_ty_ma_id" value={"2"} />
                            <input type="hidden" name="acc_inf_id" id="acc_inf_id" value={userObject[0].app_user_id}/>
                          
                            <input type="hidden" name="lsImages" id="lsImages" />
                            <input type="hidden" name="lsVideo" id="lsVideo"/>
                            <input type="hidden" name="lsPdf" id="lsPdf"/>
                          
                            <div class="card-body">
                                <div className="row">
                                    <div className="col-md-4">
                                    <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <button type="button" class="btn btn-danger">Campaign Name</button>
                  </div>
                 
                  <input id="camp_name" name="camp_name" type="text" maxlength={10} class="form-control required" />
                </div>
                                    </div>
                                    
                                </div>
                                <div class="row">
                                <div className='col-md-4'>
                                <div class="form-group">
                                            <label for="exampleInputEmail1">Numbers*</label>
                                            <div class="input-group input-group-sm">
                                            <textarea style={{height:"50vh"}} id="contact_no" name="contact_no" class="textarea form-control required" placeholder="Numbers"
                                                              ></textarea>
                                            </div>
                                        </div>
                                </div>
<div className="col-md-8">
    <div className="row">
                                <div class="col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Message*</label>
                                            <div class="input-group input-group-sm">
                                            <textarea rows="5" id="msg_text" name="msg_text" maxlength={1500} className="textarea form-control required" placeholder="Place some text here"
                                                              ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                    <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <button type="button" class="btn btn-success">Link Caption & Link</button>
                    
                  </div>
                  <div class="input-group-prepend">
                  <input id="cap_txt" name="cap_txt" type="text" class="form-control" placeholder="catption" />
                    </div>
                 
                  <input id="cap_link" name="cap_link" type="text" class="form-control" placeholder="link" />
                </div>
                                    </div>

                                    <div className="col-12">
                                    <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <button type="button" class="btn btn-secondary">Call Caption & Number</button>
                    
                  </div>
                  <div class="input-group-prepend">
                  <input id="call_txt" name="call_txt" type="text" class="form-control" placeholder="catption" />
                    </div>
                 
                  <input id="call_number" name="call_number" type="number" class="form-control" placeholder="10 digit number" />
                </div>
                                    </div>
                                    <div className="col-12">
                                    <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <button type="button" class="btn btn-primary">Wa Caption, Number & Message</button>
                    
                  </div>
                  <div class="input-group-prepend">
                  <input id="waap_ttxt" name="waap_ttxt" type="text" class="form-control" placeholder="catption" />
                    </div>
                    <div class="input-group-prepend">
                  <input id="waap_number" name="waap_number" type="number" class="form-control" placeholder="10 digit number" />
                    </div>
                 
                  <input id="waap_txt" name="waap_txt" type="text" class="form-control" placeholder="text.." />
                </div>
                                    </div>

                                    <div class="col-12">
                                       {/* upload file 1 */}
                                       <div className="card card-info">
<div className="card-header">
  <h3 className="card-title">Image Upload </h3><small> (Max File Size 1MB) </small>
</div>
<div className="card-body">
<div className="row audioLibThumb imgLibThumb">
                      
                      </div>
  <div className="row">
  <div className="col-12">
    <input type="file" ref_img="ImageThumbBack" multiple name="customFileBr" id="customFileBr" accept="image/*" className="updImgThumb" />
      <a href="javascript:void(0)" setTo="customFileBr" className="_brFileThumb updthumb">Click & Browse Image Files</a>
    </div>
   
   
  </div>
</div>

</div>
{/* end of file upload */}
                                    </div>
                                    {/* video upload */}
                                    <div class="col-6">
                                       {/* upload file 1 */}
                                       <div className="card card-success">
<div className="card-header">
  <h3 className="card-title">Video Upload </h3><small> (Max File Size 4MB)</small>
</div>
<div className="card-body">
<div className="row audioLibThumb vidLibThumb">
                      
                      </div>
  <div className="row">
  <div className="col-12">
      <input type="file" ref_img="VideoThumbBack" multiple name="videoFileBr" id="videoFileBr" className="updImgThumb" accept="video/*" />
    <a href="javascript:void(0)" setTo="videoFileBr" className="_brFileThumb updthumb">Click & Browse Video Files</a>
    </div>
   
   
  </div>
</div>

</div>
{/* end of file upload */}
                                    </div>
                                    {/* end of video upload  */}

{/* pdf upload */}
                                    <div class="col-6">
                                       {/* upload file 1 */}
                                       <div className="card card-danger">
<div className="card-header">
  <h3 className="card-title">Pdf Upload </h3><small> (Max File Size 1MB)</small>
</div>
<div className="card-body">
<div className="row audioLibThumb pdfLibThumb">
                      
                      </div>
  <div className="row">
  <div className="col-12">
      <input type="file" ref_img="PdfThumbBack" multiple name="PdfFileBr" id="pdfFileBr" className="updImgThumb" accept="pdf/*" />
    <a href="javascript:void(0)" setTo="pdfFileBr" className="_brFileThumb updthumb">Click & Browse Pdf Files</a>
    </div>
   
   
  </div>
</div>

</div>
{/* end of file upload */}
                                    </div>
{/* end of pdf upload */}


                                </div>
                                </div>
                                </div>  
                                <div className="col-md-6 respSSL"></div>
                                <div id="testing2dd2222"></div>
                                <div class="row">
                                <div className="col-3">
                                  <LoadCanvasTemplate />
                                  <div className="form-group">
                                  <input id="user_captcha" name="user_captcha" type="text" class="form-control required" />
                                    </div>
                                  </div>
                                    <div class="col-12">
                                        <div class="form-group">

                                            <div class="input-group input-group-sm" style={{"justify-content":"center"}}>
                                                <button type="button" onClick={()=>this.saveWappCompaign()} class="btn btn-primary">Send Now</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </form>
    </div>
    </div>
</section>
      
</div>
    
</div>
</div>
</div>
<Alert_Content/>
<Footer/>

          </div>)
      }
}
export default Wapp_Button_Campaign;