import React from 'react';
const Wa_Client_Numbers=(props)=>{
    //alert(JSON.stringify(props));
        return(<div className="modal fade bd-example-modal-xl"  id="divClientNumbers" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
          <div className="modal-header bg-primary">
              <h5 className="modal-title text-white" id="exampleModalLabel">Add Client Numbers</h5>
              <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" className="text-white">&times;</span>
              </button>
            </div>
            <form id="frmClientNumbers" name="frmClientNumbers">
           
            <div className="modal-body">
            <div className="row">
                      {/* <!-- left column --> */}
                      <div className="col-md-12">
                          <div className="card card-danger">
                                  <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                                  <input type="hidden" name="curl_type" id="curl_type" value="addNewClientNo" />
                                  <input type="hidden" name="m_type" id="m_type" value="addNewClientNo" />
                                  <input type="hidden" name="type_id" id="type_id" value="2" />
                                  <input type="hidden" name="local_token" id="local_token" value={props.user_object[0].app_user_id} />
                                  <div className="card-body">
                                      <div className="row">
                                          <div className="col-lg-12 col-md-12">
                                              <div className="form-group">
                                                  <label for="exampleInputEmail1">Client Number</label>
                                                  <textarea id="numbers" name="numbers" className="form-control required" placeholder="Client Numbers(,)Separated"></textarea>
                                                  
                                              </div>
                                          </div>
                                      </div>
                                    
                                      <div className="col-md-6 respSSL"></div>
                                      <div id="testing2dd2222"></div>
                                  </div>
                              
                          </div>
                      </div>
                  </div>
            </div>
            <div className="modal-footer">
            <div id="getReponse" style={{color:"red"}}></div>
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="button" className="btn _btnSetColor btn-primary">Add New</button>
            </div>
            </form>
          </div>
          </div>
          </div>
      );
    
}
export default Wa_Client_Numbers;
