import React from 'react';
const Wa_User_Limits=(props)=>{
    
        return(<div className="modal fade bd-example-modal-xl"  id="divWaUserLimit" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
          <div className="modal-header bg-primary">
              <h5 className="modal-title text-white" id="exampleModalLabel">Set {props.act_type}</h5>
              <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" className="text-white">&times;</span>
              </button>
            </div>
            <form id="frmWaUserLimit" name="frmWaUserLimit">
           
            <div className="modal-body">
            <div className="row">
                      {/* <!-- left column --> */}
                      <div className="col-md-12">
                          <div className="card card-danger">
                                  <input type="hidden" name="res_func" id="res_func" value="okSuccLimitSet" />
                                  <input type="hidden" name="curl_type" id="curl_type" value="userLimitSettings" />
                                  <input type="hidden" name="m_type" id="m_type" value="userLimitSettings" />
                                  <input type="hidden" name="type_id" id="type_id" value="2" />
                                  <input type="hidden" name="acc_inf_id" id="acc_inf_id" value={props.act_user.id} />
                                  <input type="hidden" name="added_by_id" id="added_by_id" value={props.app_user_id}/>
                                  <input type="hidden" name="act_type" id="act_type" value={props.act_type}/>
                                  <input type="hidden" name="setting_id" id="setting_id" value={props.act_user.setting_id!=="" && typeof props.act_user.setting_id!=="undefined" ?props.act_user.setting_id:'-1'}/>
                                  <div className="card-body">
                                      <div className="row">
                                     
                                          <div className="col-lg-2 col-md-2">
                                              <div className="form-group">
                                                  <label for="exampleInputEmail1">Business/Person Name</label>
                                                  <div className="input-group input-group-sm">
                                                      <input type="text" readonly={"true"} id="first_name" name="first_name" className="form-control" value={typeof props.act_user.first_name!=="undefined"?props.act_user.first_name:''}/>
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="col-lg-2 col-md-2">
                                              <div className="form-group">
                                                  <label for="exampleInputEmail1">User Name</label>
                                                  <div className="input-group input-group-sm">
                                                      <input type="text" readonly={"true"} id="user_name" name="user_name" className="form-control" value={typeof props.act_user.user_name!=="undefined"?props.act_user.user_name:''} />
                                                  </div>
                                              </div>
                                          </div>
                                         
                                       
                                          
                                          <div className="col-lg-2 col-md-2">
                                              <div className="form-group">
                                                  <label for="exampleInputEmail1">Limit *</label>
                                                  <div className="input-group input-group-sm">
                                                  <input type="number" id="limit_to" name="limit_to" className="form-control required" placeholder="" />
                                                  </div>
                                              </div>
                                          </div>
                                  
                                      </div>
                                    
                                      <div className="col-md-6 respSSL"></div>
                                      <div id="testing2dd2222"></div>
                                  </div>
                              
                          </div>
                      </div>
                  </div>
            </div>
            <div className="modal-footer">
            <div id="getReponse" style={{color:"red"}}></div>
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="button" className="btn _btnSetColor btn-primary">Save changes</button>
            </div>
            </form>
          </div>
          </div>
          </div>);
    
}
export default Wa_User_Limits;
