import React from 'react';
import {getWaUrlPath} from '../function_lib/common_lib';
const Comp_View_Media=(props)=>{
    let strMedia="";
   //alert(JSON.stringify(props));

    if(typeof props.media==="undefined"){
       if(typeof props.listMedia.img_files!=="undefined" && props.listMedia.img_files!==null){

           if(props.listMedia.img_files.trim()!==""){
            strMedia=props.listMedia.img_files.trim();
           }

           if(props.listMedia.vid_files.trim()!==""){
            if(strMedia!==""){
                strMedia+=",";
            }
            strMedia=props.listMedia.vid_files.trim();
           }

           if(props.listMedia.pdf_files.trim()!==""){
            if(strMedia!==""){
                strMedia+=",";
            }
            strMedia=props.listMedia.pdf_files.trim();
           }

       }
    }else{
        strMedia=props.media;
    }

    //alert(strMedia);

    let arrMedia=strMedia.split(',');

    

    let mediUri=getWaUrlPath();
    //alert(mediUri);
        return(<div className="modal fade bd-example-modal-xl"  id="divViewMedia" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
          <div className="modal-header bg-primary">
              <h5 className="modal-title text-white" id="exampleModalLabel">View Media</h5>
              <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" className="text-white">&times;</span>
              </button>
            </div>
            
           
            <div className="modal-body">
            <div className="row">
                      {/* <!-- left column --> */}
                      <div className="col-md-12">
                          <div className="card card-danger">
                                   <div className="card-body">
                                      <div className="row">
                                        <div className="col-md-12">
                                          {typeof props.listMedia !=="undefined"?props.listMedia.msg_text:''}
                                        </div>
                                     {arrMedia.map((item,index)=>{
                                      let isFileSet=false;
                                        if(item.trim()!==""){
                                        return (<div className="col-lg-4 col-md-4">
                                      {(item.indexOf(".pdf")!==-1)?
          (<embed width="100%" height="500px" src={mediUri+item+'#toolbar=0&navpanes=0'} toolbar="0"></embed>)
          :item.indexOf(".mp4")!==-1?<embed width="100%" height="500px" src={mediUri+item+'#toolbar=0&navpanes=0'} toolbar="0"></embed>:<img max-width="100%" className='img-responsive' src={mediUri+item} />}
                                                                                      
                                                                                  </div>)
}})}
                                          
                                          
                                          
                                          
                                         
                                   <div className="col-lg-12 col-md-12">
                                  

                                   </div>
                                      </div>
                                    
                                      <div className="col-md-6 respSSL"></div>
                                      <div id="testing2dd2222"></div>
                                  </div>
                              
                          </div>
                      </div>
                  </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
            
          </div>
          </div>
          </div>);
    
}
export default Comp_View_Media;
