import $ from 'jquery';
import React from 'react';
import UserToken,{getAccessFunctions} from '../function_lib/userToken';
import {AjaxCallMethod,getAccessRolesNew,RespBkMthdMapp} from '../function_lib/common_lib';
//import {CallCMSMapMethod,AjaxCallMethodCMS} from '../function_lib/common_cms';

import { Player } from 'video-react';
import LogoImg from '../assets/images/logo_p_small.png';
import GooglePlay from '../assets/images/google-play.jpg';
import AppStore from '../assets/images/mac.jpg';
import './index.css';


let loginUser="";
let userObject="";
class Index extends React.Component{
  constructor(props){
    super(props);
    if(localStorage.getItem("userData")!=null){
      UserToken.setUserObject(localStorage.getItem("userData"));
      userObject= UserToken.getUserObject();
      loginUser=userObject[0].person_name;
      window.location.href="./Dashboard";
    }
  }

  checkLogin(){
    if($("#p1").val()==="acta" && $("#p2").val()==="acta123"){
      let datat=[];
      datat.push({"id":"nBwJY07WZF7E0ew-8Pd0966rdtKfpKPorGMq2OOrZqQG0LimGvQzs2K27TOOnPHB3hvoha5jqygNtyGMeUYycw",
      "app_user_id":"nBwJY07WZF7E0ew-8Pd0966rdtKfpKPorGMq2OOrZqQG0LimGvQzs2K27TOOnPHB3hvoha5jqygNtyGMeUYycw",
    "first_name":"Admin",
  "last_name":"Admin",
"type":"1"});
      this.okSucc(JSON.stringify(datat),"success");
    }else{
      this.okSucc("0","");
    }
  }

  okSucc(data,datares){
   // alert(data+datares);
   // return;
    if(data!="0" && data!=""){
      $("#getReponse").html("Login Successfully..");
     localStorage.setItem('userData',data);
     let ret= getAccessFunctions();
     window.location.href="./dashboard";
     if(ret==="all"){
      //window.location.href="./dashboard";
     }else{
      //window.location.href="./library_master";
     }
     
    }else{
      $(".respSSL").html("Invalid UserName or Password !");
    }
}
componentDidMount(){
  AjaxCallMethod(this,"okSuccCNOT");
  //RespBkMthdMapp(this,"","keyEvent");
}
    render(){
        return(<div>
          <div className="area" >
                      <ul className="circles">
                              <li></li>
                              <li></li>
                              <li></li>
                              <li></li>
                              <li></li>
                              <li></li>
                              <li></li>
                              <li></li>
                              <li></li>
                              <li></li>
                      </ul>
              </div >
            
            <div className="container-fluid d-set ggg" >
            <div className="row">
              
              
              <div className="col-lg-4 col-md-4 col-11 m-auto d-block">
              <div className="card shadow-lg">
                <div className="card-header">
                  
                  <h3 className="text-center"><img src={LogoImg} className="img-fluid"  /></h3>
                  <div className="border-bottom"></div>
                <h6 className="text-center"><span className="material-icons text-orange md-18 pt-1 font-small fgg">
          verified
          </span> Log in using your registered Detail <br></br>(for demo user only)</h6>
                
                </div>
                          <div className="card-body">
                          <form id="frmLogin" name="frmLogin" action="#">
                          <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                    <input type="hidden" name="curl_type" id="curl_type" value="logint" />
                    <input type="hidden" name="m_type" id="m_type" value="logint" />
                    {/* <div className="form-group">
                             
                              <input type="text" className="form-control" id="txtCompanyCode" name="txtCompanyCode" placeholder="Company Code.." />
                            </div> */}
                            <div className="form-group">
                             
                              <input type="text" className="form-control" id="p1" name="p1" placeholder="UserName.." />
                            </div>
                            <div className="form-group">
                              
                              <input type="password" className="form-control keyEvent_btn" rf="#btnLogin" id="p2" name="p2" placeholder="Password.." />
                            </div>
                            <div class="col-12 respSSL"></div>
                            <div className="form-group">
                  <button type="button" id="btnLogin" className="btn _btnSetColor btn-sm float-left btn-success">Submit</button>   <a className="font-small float-right float-right text-cyan" href="" >Forgot Password ?</a>
                  
                            </div>
                            </form>
                </div>
                
                       <div className="border-bottom w-100"></div>
                {/* <h6 className="mt-3 ml-4 text-orange"	>Download App Now</h6> */}
                <div className="row p-1">
                  
                  
                {/* <div className="col-lg-6 col-md-6 col-12">
                  <h1 className="text-center"><img src={GooglePlay} className="img-fluid" width="150" /></h1>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <h1 className="text-center"><img src={AppStore} className="img-fluid" width="150" /></h1>
                </div> */}
                </div>
                
                
              
                
                
                </div>
              
              
              
              
              </div>
              
              
              
              </div>	
            </div>
              </div>);
    }
}
export default Index