import $, { extend } from 'jquery';
import React from 'react';
import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,GetImgCertUser_CBack,RespBkMthdMapp,detectEmojisAndSpecialCharacters,CallMapMethod_CBack} from '../function_lib/common_lib';
//import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

import {Custom_Alert,Alert_Content} from '../components/custom_alert';
import Header from '../user_pages/header';
import Footer from '../user_pages/footer';


import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';
import pdfIcon from '../assets/images/pdf_icon.png';
import mp3Icon from '../assets/images/mp3_icon.png';
import mp4Icon from '../assets/images/mp4_icon.png';

let loginUser="";
let userObject="";
let companyName="";
let clsUserProf;

let totalFileAllow=1;

let oldPassword="";

class User_Profile extends React.Component{
  logout(){
    localStorage.clear();
    window.location.href="../";
  }
    forceUpdateHandler(){
        this.forceUpdate();
      };
    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }
      this.state={listUsers:[]}
      this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
      clsUserProf=this;
      }
getProfile(){
     //child component use this method loan_document_upload_comp
     var map=[];
     map.push({name:"curl_type",value:"getUsers"});
     map.push({name:"res_func",value:"callBackrrr"});
     map.push({name:"local_token",value:userObject[0].id});
     map.push({name:"local_id",value:userObject[0].id});
     map.push({name:"user_type",value:userObject[0].type});
     map["res_func"]="callBackrrr";
     map["curl_type"]="getUsers";
     CallMapMethod_CBack(clsUserProf,map,function(data,msg){
       //alert(data);
         if(data!=="0" && data!==""){
             let listData=JSON.parse(data);
             var strData="";
             clsUserProf.setState({listUsers:listData},()=>{
               if(listData.length>0){
                oldPassword=listData[0]["password"];
               }
               clsUserProf.forceUpdateHandler();
             });
           }
     });
}
resetPassword(){
  if($("#old_password").val()!==oldPassword){
    alert("Old Password not Matched !");
    return;
  }
  GetImgCertUser_CBack(clsUserProf,"#frmPwdChange","updPasswordChg",function(checkData,checkMsg,userMsg){
    //alert(checkData);
   
    if(checkData===-10){
      alert("Kindly fill required fields");
      return;
    }else if(checkData==="002"){
      Custom_Alert("Change Password",checkMsg,false);
    }else if(checkData==="001"){
      Custom_Alert("Change Password",checkMsg,false);
      clsUserProf.logout();
    }
    else {
      Custom_Alert("Change Password",checkMsg);
      //alert(checkMsg);
    }
    
  })
}

 
componentDidMount(){
    this.getProfile();
  //loadCaptchaEnginge(4,'white','black','lower'); 
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].id);
}

   render(){
          return(<div>
<Header/>

<section class="content">
<div class="container-sm">
<div class="card card-secondary">
<div class="card-header">
                                <h3 class="card-title">Profile</h3>
                            </div>
                            <form class="form-group" id="frmPwdChange" name="frmPwdChange" enctype="multipart/form-data" method="POST" onsubmit="event.preventDefault()">
                            <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                            <input type="hidden" name="curl_type" id="curl_type" value="updPasswordChg" />
                            <input type="hidden" name="m_type" id="m_type" value="updPasswordChg" />
                            <input type="hidden" name="acc_inf_id" id="acc_inf_id" value={userObject[0].app_user_id}/>
                            <input type="hidden" name="local_id" id="local_id" value={userObject[0].id}/>

                          
                            <div class="card-body">
                                
                                  {this.state.listUsers.map((item,index)=>{
                                    return (<div className="row">
                                        <div className="col-md-4">
                                    <div class="input-group">
                                    <label for="exampleInputEmail1">Company Name*</label>
                                    <div class="input-group input-group-sm">
                                     <input id="d1" name="d1" type="text" readOnly={true} class="form-control" value={item.first_name} />
                                     </div>
                                    </div>
                                    </div>

                                    <div className="col-md-4">
                                    <div class="input-group">
                                    <label for="exampleInputEmail1">Person Name*</label>
                                    <div class="input-group input-group-sm">
                                     <input id="d2" name="d2" type="text" readOnly={true} class="form-control" value={item.last_name} />
                                     </div>
                                    </div>
                                    </div>

                                    <div className="col-md-4">
                                    <div class="input-group">
                                    <label for="exampleInputEmail1">Contact No*</label>
                                    <div class="input-group input-group-sm">
                                     <input id="d3" name="d3" type="text" readOnly={true} class="form-control" value={item.contact_no} />
                                     </div>
                                    </div>
                                    </div>

                                    <div className="col-md-4">
                                    <div class="input-group">
                                    <label for="exampleInputEmail1">Email Id*</label>
                                    <div class="input-group input-group-sm">
                                     <input id="d4" name="d4" type="text" readOnly={true} class="form-control" value={item.email_id} />
                                     </div>
                                    </div>
                                    </div>

                                    <div className="col-md-4">
                                    <div class="input-group">
                                    <label for="exampleInputEmail1">UserName*</label>
                                    <div class="input-group input-group-sm">
                                     <input id="d5" name="d5" type="text" readOnly={true} class="form-control" value={item.user_name} />
                                     </div>
                                    </div>
                                    </div>
                                    
                                    </div>)
                                  })}  


                        <div className="row">
                            
                            <div class="col-12">
                                       {/* upload file 1 */}
                                       <div className="card card-info">
<div className="card-header">
  <h3 className="card-title">Change Password </h3>
</div>
<div className="card-body">

  <div className="row">
  <div className="col-md-4">
                                    <div class="input-group">
                                    <label for="exampleInputEmail1">Old Password*</label>
                                    <div class="input-group input-group-sm">
                                     <input id="old_password" name="old_password" type="password"  class="form-control required"  />
                                     </div>
                                    </div>
                                    </div>

                                    <div className="col-md-4">
                                    <div class="input-group">
                                    <label for="exampleInputEmail1">New Password*</label>
                                    <div class="input-group input-group-sm">
                                     <input id="new_password" name="new_password" type="password"  class="form-control required"  />
                                     </div>
                                    </div>
                                    </div>
                                    <div className="col-md-4">
                                    <div class="input-group">
                                    <label for="exampleInputEmail1">Confirm New Password*</label>
                                    <div class="input-group input-group-sm">
                                     <input id="new_conf_password" name="new_conf_password" type="password" class="form-control required"  />
                                     </div>
                                    </div>
                                    </div>
   
   
  </div>
</div>

</div>
{/* end of file upload */}
                                   

                            </div>
                            </div>      
                                     
                                
                               
                                <div className="col-md-6 respSSL"></div>
                                <div id="testing2dd2222"></div>
                                <div class="row">
                                  

                                    <div class="col-12">
                                      
                                        <div class="form-group">
                                        <center>
                                            <div class="input-group input-group-sm" style={{"justify-content":"center"}}>
                                                <button type="button" onClick={()=>this.resetPassword()}  class="btn btn-primary">Change Password</button>
                                            </div>
                                        </center>
                                        </div>
                                        
                                    </div>
                                </div>
                                </div>
                            </form>
    </div>
    </div>
</section>
      

    
<Alert_Content/>
<Footer/>

          </div>)
      }
}
export default User_Profile;