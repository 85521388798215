import $, { extend } from 'jquery';
import React from 'react';
import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,CallMapMethod_CBack,setEventsLib} from '../function_lib/common_lib';
import Wa_Client_Numbers from '../components/wa_client_numbers';
import Header from '../user_pages/header';
import Footer from '../user_pages/footer';
import Option_Module from '../user_pages/option_module';
import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';



let loginUser="";
let userObject="";
let companyName="";
let clsClientNos;

let rf="0";

class Client_Nos extends React.Component{
    forceUpdateHandler(){
        this.forceUpdate();
      };

    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
            userObject= getUserObjData();
            //alert(JSON.stringify(userObject));
            loginUser=userObject[0].first_name;
            companyName=userObject[0].first_name;
        }
        clsClientNos=this;
        this.state={ listThrashold:[]};
          this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
      }

      editThrasholdData(e_id,e_key,text){
        var map=[];
        map.push({name:"curl_type",value:"editThrashold"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        map.push({name:"upper_id",value:userObject[0].upper_id});

        map.push({name:"e_id",value:e_id});
        map.push({name:"e_key",value:e_key});
        map.push({name:"e_value",value:text});

        map["res_func"]="callBackrrr";
        map["curl_type"]="editThrashold";
        CallMapMethod_CBack(clsClientNos,map,function(data,msg){
          if(data==="001"){
            alert("Updated Successfully");
          }   
        })
      }

      getClientNos(status="0"){
        //child component use this method loan_document_upload_comp
        var map=[];
        map.push({name:"curl_type",value:"getClientNos"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        map.push({name:"upper_id",value:userObject[0].upper_id});
        map["res_func"]="callBackrrr";
        map["curl_type"]="getClientNos";
        CallMapMethod_CBack(clsClientNos,map,function(data,msg){
          //alert(data);
            if(data!=="0" && data!==""){
                let listData=JSON.parse(data);
                var strData="";
                clsClientNos.setState({listThrashold:listData},()=>{
                    clsClientNos.forceUpdateHandler();
                  
                  //RespBkMthdMapp(clsUserMgmt,"_btnShareOption","");
                 
                  if(rf!=="0"){
                    clsClientNos.getSearch(rf);
                    //alert("i m in setup "+rf);
                    $("#serLoanStatus").val(rf);
                  }
                });
                
               
              }
        });
      }
      
      okSucc(data,msg){
        
        if(data==="001"){
          alert(msg);
          clsClientNos.getClientNos();
        }
        
      }    
      
componentDidMount(){
   this.getClientNos();

  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].app_user_id);
}


      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
    
<div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
         
      <div class="card">
              {/* <!-- /.card-header --> */}
              <div class="card-header bg-cyan">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>Client Nos</b></h3>
                <a className="btn btn-sm btn-danger float-right" data-toggle="modal" data-target="#divClientNumbers" href="javascript:void(0)">Add New</a>
              </div>
                <table class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap table-responsive">
                  <thead>
                    <tr class="border">
                      <th>S.No.</th>
                      <th>Client Nos</th>
                      <th>User/Company</th>
                    </tr>
                  </thead>
                  <tbody id="tbData">
                  {(this.state.listThrashold.map((item,index)=>{
                       return(<tr>
                        <td>{index+1}</td>
                    <td>{item.numbers}</td>
                    <td>{item.users_by}</td>
                  
                    </tr>)
                  }))}
                  </tbody>
                </table>
           
              
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Footer/>
<Wa_Client_Numbers user_object={userObject}/>
          </div>)
      }
}
export default Client_Nos;