import $, { extend } from 'jquery';
import React from 'react';
import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,CallMapMethod_CBack} from '../function_lib/common_lib';
import Header from '../user_pages/header';
import Footer from '../user_pages/footer';
import Option_Module from '../user_pages/option_module';
import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';


let loginUser="";
let userObject="";
let companyName="";
let clsCreditHistory;
class Credit_History extends React.Component{
  forceUpdateHandler(){
    this.forceUpdate();
  };
    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }
      this.state={creditBalance:[]};
          this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
      clsCreditHistory=this;
      }
getCreditBalance(){
        var map=[];
        map.push({name:"curl_type",value:"getCreditBalance"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        //map.push({name:"acc_inf_id",value:userObject[0].app_user_id});
        
        map["res_func"]="callBackrrr";
        map["curl_type"]="getCreditBalance";
        CallMapMethod_CBack(clsCreditHistory,map,function(data,msg){
          //alert(data);
            if(data!=="0" && data!==""){
                let listData=JSON.parse(data);
                var strData="";
                clsCreditHistory.setState({creditBalance:listData},()=>{
                  clsCreditHistory.forceUpdateHandler();
                })
              }
        });
}
componentDidMount(){
   this.getCreditBalance();

  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].app_user_id);
}


      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
    
<div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
      <div class="card">
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>Credit History</b></h3>
              </div>
                <table class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap table-responsive">
                  <thead>
                    <tr class="border">
                      <th>S.No.</th>
                      <th>User Name</th>
                      <th>Entity Name</th>
                      <th>Route</th> 
                      <th>Credits</th>
                      <th>Available Balance</th>
                      <th>Transaction Date</th>
                      {/* <th>Action</th> */}
                    </tr>
                  </thead>
                  <tbody id="tbData">
                    {this.state.creditBalance.map((item,index)=>{
                      return(<tr>
                        <td>{index+1}</td>
                    <td>{item.user_name}</td>
                  <td>{item.first_name}</td>
                  <td>{item.route_name}</td>
                  <td>{item.trans_value}</td>
                  <td>{item.balance}</td>
                  <td>{item.creation_date}</td>
                    </tr>)
                    })}
                  </tbody>
                </table>
             
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Footer/>
<div className="modal fade bd-example-modal-xl"  id="divEmployee" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-xl" role="document">
    <div className="modal-content">
    <div className="modal-header bg-primary">
        <h5 className="modal-title text-white" id="exampleModalLabel">Add New</h5>
        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" className="text-white">&times;</span>
        </button>
      </div>
      <form id="frmCreate" name="frmCreate">
     
      <div className="modal-body">
      <div className="row">
                {/* <!-- left column --> */}
                <div className="col-md-12">
                    <div className="card card-danger">
                            <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                            <input type="hidden" name="curl_type" id="curl_type" value="createLogMaster" />
                            <input type="hidden" name="m_type" id="m_type" value="createLogMaster" />
                            <input type="hidden" name="type_id" id="type_id" value="2" />
                            <input type="hidden" name="app_user_id" id="app_user_id" value="" />
                            <div className="card-body">
                                <div className="row">
                               
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">License/Registration Name</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="details" name="details" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Type *</label>
                                            <div className="input-group input-group-sm">
                                                <select id="parent_id_reg" name="parent_id_reg" className="form-control">
                                                  <option value="0">-Select-</option>
                                                  <option value="entity">Entity</option>
                                                  <option value="contractor">Contractor</option>
                                                  <option value="client">Client</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Valid From</label>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="fa fa-calendar"></i></span>
                    </div>
                    <input type="text" id="col98" name="col98" className="form-control" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/mm/yyyy" data-mask />
                  </div>
              <small id="emailHelp" className="form-text text-muted">Proposal Form Sign Date</small> </div>
          </div>
                                    <div className="col-lg-3 col-md-3 col-12">
            <div className="form-group">
              <label for="exampleInputEmail1">Valid To</label>
              <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="fa fa-calendar"></i></span>
                    </div>
                    <input type="text" id="col98" name="col98" className="form-control" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/mm/yyyy" data-mask />
                  </div>
              <small id="emailHelp" className="form-text text-muted">Proposal Form Sign Date</small> </div>
          </div>
                                    <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
<label for="exampleInputFile">Upload Proof(*img,pdf)</label>
<div className="input-group">
  <div className="custom-file">
    <input type="file" className="custom-file-input" name="exampleInputFile" id="exampleInputFile" />
    <label className="custom-file-label" for="exampleInputFile">Choose file</label>
  </div>
  {/* <div className="input-group-append">
  <button type="button" className="btn btn-primary">Upload Now</button>
  </div> */}
</div>
</div>
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                    <div className="form-group">
                                            <label for="exampleInputEmail1">Reminder Breif(Days) *</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="ref1_id" name="ref1_id" className="form-control" placeholder="Priority Index" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              
                                <div className="col-md-6 respSSL"></div>
                                <div id="testing2dd2222"></div>
                            </div>
                        
                    </div>
                </div>
            </div>
      </div>
      <div className="modal-footer">
      <div id="getReponse" style={{color:"red"}}></div>
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" className="btn _btnSetColor btn-primary">Save changes</button>
      </div>
      </form>
    </div>
    </div>
    </div>
          </div>)
      }
}
export default Credit_History;